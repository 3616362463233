import { getPublicImage } from '@/utils/image';
import { CharacterCreationChoices } from '../../../models/choices';

export interface CharacterTrait {
  id: string;
  name: string;
  asset: string | string[] | null;
  description?: string | null;
}

export interface Style extends CharacterTrait { }
export interface Ethnicity extends CharacterTrait { }
export interface Age extends CharacterTrait { }
export interface EyesColor extends CharacterTrait { }
export interface HairStyle extends CharacterTrait { }
export interface HairColor extends CharacterTrait { }
export interface BodyType extends CharacterTrait { }
export interface BreastSize extends CharacterTrait { }
export interface ButtSize extends CharacterTrait { }
export interface Clothing extends CharacterTrait { }
export interface Relationship extends CharacterTrait { }
export interface Personality extends CharacterTrait { }
export interface Voice extends CharacterTrait { }
export interface FacialExpression extends CharacterTrait { }
export interface PhysicalPosition extends CharacterTrait { }

export const genders: Style[] = [
  { id: 'female', name: 'Female', asset: null },
  { id: 'male', name: 'Male', asset: null },
  { id: 'futa', name: 'Futanari', asset: null },
];

export const styles: { [key: string]: Style[] } = {
  'female': [
    { id: 'realistic', name: 'Realistic', asset: [getPublicImage('avatars/style_realistic.png'), getPublicImage('avatars/style_realistic_alternate_v2_sex.png')] },
    { id: 'artistic', name: 'Anime', asset: [getPublicImage('avatars/style_artistic.png'), getPublicImage('avatars/style_artistic_alternate_v2_sex.png')] },
  ],
  'male': [
    { id: 'realistic', name: 'Realistic', asset: [getPublicImage('character_creation/ethnicity/male_realistic_caucasian.png'), getPublicImage('character_creation/ethnicity/male_realistic_caucasian.png')] },
    { id: 'artistic', name: 'Anime', asset: [getPublicImage('character_creation/ethnicity/male_artistic_caucasian.png'), getPublicImage('character_creation/ethnicity/male_artistic_caucasian.png')] },
  ],
  'futa': [
    { id: 'realistic', name: 'Realistic', asset: [getPublicImage('avatars/style_realistic.png'), getPublicImage('avatars/futa_realistic_alternate.png')] },
    { id: 'artistic', name: 'Anime', asset: [getPublicImage('avatars/style_artistic.png'), getPublicImage('avatars/futa_artistic_alternate.png')] },
  ],
};

// =================================== Ethnicity ===================================
export const ethnicities: { [key: string]: Ethnicity[] } = {
  'realistic_female': [
    { id: 'caucasian', name: 'Caucasian', asset: getPublicImage('character_creation/ethnicity/realistic_caucasian_v4.png') },
    { id: 'latina', name: 'Latina', asset: getPublicImage('character_creation/ethnicity/realistic_latina_v4.png') },
    { id: 'asian', name: 'Asian', asset: getPublicImage('character_creation/ethnicity/realistic_asian_v4.png') },
    { id: 'black', name: 'Black', asset: getPublicImage('character_creation/ethnicity/realistic_black_v4.png') },
    { id: 'indian', name: 'Indian', asset: getPublicImage('character_creation/ethnicity/realistic_indian_v4.png') },
    { id: 'elf', name: 'Elf', asset: getPublicImage('character_creation/ethnicity/realistic_elf_v4.png') },
    { id: 'cyborg', name: 'Cyborg', asset: getPublicImage('character_creation/ethnicity/realistic_cyborg_v4.png') },
    { id: 'catgirl', name: 'Cat Girl', asset: getPublicImage('character_creation/ethnicity/realistic_catgirl_v4.png') }
  ],
  'artistic_female': [
    { id: 'caucasian', name: 'Caucasian', asset: getPublicImage('character_creation/ethnicity/artistic_caucasian_v3.png') },
    { id: 'latina', name: 'Latina', asset: getPublicImage('character_creation/ethnicity/artistic_latina_v3.png') },
    { id: 'asian', name: 'Asian', asset: getPublicImage('character_creation/ethnicity/artistic_asian_v3.png') },
    { id: 'black', name: 'Black', asset: getPublicImage('character_creation/ethnicity/artistic_black_v3.png') },
    { id: 'elf', name: 'Elf', asset: getPublicImage('character_creation/ethnicity/artistic_elf_v3.png') },
    { id: 'devil', name: 'Devil', asset: getPublicImage('character_creation/ethnicity/artistic_devil.png') },
    { id: 'goblin', name: 'Goblin', asset: getPublicImage('character_creation/ethnicity/artistic_goblin.png') },
    { id: 'catgirl', name: 'Catgirl', asset: getPublicImage('character_creation/ethnicity/artistic_catgirl.png') },
    { id: 'dark elf', name: 'Dark Elf', asset: getPublicImage('character_creation/ethnicity/artistic_darkelf_v3.png') },
    { id: 'cyborg', name: 'Cyborg', asset: getPublicImage('character_creation/ethnicity/artistic_cyborg_v3.png') }
  ],

  'realistic_male': [
    { id: 'caucasian', name: 'Caucasian', asset: getPublicImage('character_creation/ethnicity/male_realistic_caucasian.png') },
    { id: 'latina', name: 'Latina', asset: getPublicImage('character_creation/ethnicity/male_realistic_latina.png') },
    { id: 'asian', name: 'Asian', asset: getPublicImage('character_creation/ethnicity/male_realistic_asian.png') },
    { id: 'black', name: 'Black / Afro', asset: getPublicImage('character_creation/ethnicity/male_realistic_black.png') },
    { id: 'indian', name: 'Indian', asset: getPublicImage('character_creation/ethnicity/male_realistic_indian.png') },
  ],
  'artistic_male': [
    { id: 'caucasian', name: 'Caucasian', asset: getPublicImage('character_creation/ethnicity/male_artistic_caucasian.png') },
    { id: 'latina', name: 'Latina', asset: getPublicImage('character_creation/ethnicity/male_artistic_latina.png') },
    { id: 'asian', name: 'Asian', asset: getPublicImage('character_creation/ethnicity/male_artistic_asian.png') },
    { id: 'black', name: 'Black / Afro', asset: getPublicImage('character_creation/ethnicity/male_artistic_black.png') },
    { id: 'indian', name: 'Indian', asset: getPublicImage('character_creation/ethnicity/male_artistic_indian.png') },
  ],

  'realistic_futa': [
    { id: 'caucasian', name: 'Caucasian', asset: getPublicImage('character_creation/ethnicity/realistic_caucasian_v4.png') },
    { id: 'latina', name: 'Latina', asset: getPublicImage('character_creation/ethnicity/realistic_latina_v4.png') },
    { id: 'asian', name: 'Asian', asset: getPublicImage('character_creation/ethnicity/realistic_asian_v4.png') },
    { id: 'black', name: 'Black', asset: getPublicImage('character_creation/ethnicity/realistic_black_v4.png') },
    { id: 'indian', name: 'Indian', asset: getPublicImage('character_creation/ethnicity/realistic_indian_v4.png') },
    { id: 'elf', name: 'Elf', asset: getPublicImage('character_creation/ethnicity/realistic_elf_v4.png') },
    { id: 'cyborg', name: 'Cyborg', asset: getPublicImage('character_creation/ethnicity/realistic_cyborg_v4.png') },
    { id: 'catgirl', name: 'Cat Girl', asset: getPublicImage('character_creation/ethnicity/realistic_catgirl_v4.png') }
  ],
  'artistic_futa': [
    { id: 'caucasian', name: 'Caucasian', asset: getPublicImage('character_creation/ethnicity/artistic_caucasian_v3.png') },
    { id: 'latina', name: 'Latina', asset: getPublicImage('character_creation/ethnicity/artistic_latina_v3.png') },
    { id: 'asian', name: 'Asian', asset: getPublicImage('character_creation/ethnicity/artistic_asian_v3.png') },
    { id: 'black', name: 'Black', asset: getPublicImage('character_creation/ethnicity/artistic_black_v3.png') },
    { id: 'elf', name: 'Elf', asset: getPublicImage('character_creation/ethnicity/artistic_elf_v3.png') },
    { id: 'devil', name: 'Devil', asset: getPublicImage('character_creation/ethnicity/artistic_devil.png') },
    { id: 'goblin', name: 'Goblin', asset: getPublicImage('character_creation/ethnicity/artistic_goblin.png') },
    { id: 'catgirl', name: 'Catgirl', asset: getPublicImage('character_creation/ethnicity/artistic_catgirl.png') },
    { id: 'dark elf', name: 'Dark Elf', asset: getPublicImage('character_creation/ethnicity/artistic_darkelf_v3.png') },
    { id: 'cyborg', name: 'Cyborg', asset: getPublicImage('character_creation/ethnicity/artistic_cyborg_v3.png') }
  ]
};

// Add the test variant ethnicities
export const testEthnicities: Ethnicity[] = [
  { id: 'caucasian', name: 'Caucasian', asset: getPublicImage('character_creation/ethnicity/realistic_caucasian_v3.png') },
  { id: 'latina', name: 'Latina', asset: getPublicImage('character_creation/ethnicity/realistic_latina_v3.png') },
  { id: 'asian', name: 'Asian', asset: getPublicImage('character_creation/ethnicity/realistic_asian_v2.png') },
  { id: 'black', name: 'Black', asset: getPublicImage('character_creation/ethnicity/realistic_black_v2.png') },
  { id: 'indian', name: 'Indian', asset: getPublicImage('character_creation/ethnicity/realistic_indian_v2.png') },
];

// =================================== Age ===================================
export const ages: { [key: string]: Age[] } = {
  'realistic_female': [
    { id: '18+', name: 'Teen 18+', asset: null },
    { id: '20s', name: '20s', asset: null },
    { id: '30s', name: '30s', asset: null },
    { id: '40-55', name: '40-55', asset: null },
    // { id: '50s', name: '50s', asset: null },
    // { id: '60s', name: '60s', asset: null },
  ],
  'artistic_female': [
    { id: '18+', name: 'Teen 18+', asset: null },
    { id: '20s', name: '20s', asset: null },
    { id: '30s', name: '30s', asset: null },
    { id: '40-55', name: '40-55', asset: null },
    // { id: '40s', name: '40s', asset: null },
    // { id: '50s', name: '50s', asset: null },
    // { id: '60s', name: '60s', asset: null },
  ],

  'realistic_male': [
    { id: '18+', name: 'Teen 18+', asset: null },
    { id: '20s', name: '20s', asset: null },
    { id: '30s', name: '30s', asset: null },
    { id: '40-55', name: '40-55', asset: null },
  ],
  'artistic_male': [
    { id: '18+', name: 'Teen 18+', asset: null },
    { id: '20s', name: '20s', asset: null },
    { id: '30s', name: '30s', asset: null },
    { id: '40-55', name: '40-55', asset: null },
  ],

  'realistic_futa': [
    { id: '18+', name: 'Teen 18+', asset: null },
    { id: '20s', name: '20s', asset: null },
    { id: '30s', name: '30s', asset: null },
    { id: '40-55', name: '40-55', asset: null },
  ],
  'artistic_futa': [
    { id: '18+', name: 'Teen 18+', asset: null },
    { id: '20s', name: '20s', asset: null },
    { id: '30s', name: '30s', asset: null },
    { id: '40-55', name: '40-55', asset: null },
  ]
};

// =================================== Eyes Color ===================================
export const eyesColors: { [key: string]: EyesColor[] } = {
  'realistic_female': [
    { id: 'brown', name: 'Brown', asset: getPublicImage('character_creation/eyes_color/realistic_brown_v2.png') },
    { id: 'blue', name: 'Blue', asset: getPublicImage('character_creation/eyes_color/realistic_blue.png') },
    { id: 'green', name: 'Green', asset: getPublicImage('character_creation/eyes_color/realistic_green.png') },
  ],
  'artistic_female': [
    { id: 'brown', name: 'Brown', asset: getPublicImage('character_creation/eyes_color/artistic_brown.png') },
    { id: 'blue', name: 'Blue', asset: getPublicImage('character_creation/eyes_color/artistic_blue.png') },
    { id: 'green', name: 'Green', asset: getPublicImage('character_creation/eyes_color/artistic_green.png') },
    { id: 'yellow', name: 'Yellow', asset: getPublicImage('character_creation/eyes_color/artistic_yellow.png') },
    { id: 'red', name: 'Red', asset: getPublicImage('character_creation/eyes_color/artistic_red.png') },
  ],

  'realistic_male': [
    { id: 'brown', name: 'Brown', asset: getPublicImage('character_creation/eyes_color/male_realistic_brown.png') },
    { id: 'blue', name: 'Blue', asset: getPublicImage('character_creation/eyes_color/male_realistic_blue.png') },
    { id: 'green', name: 'Green', asset: getPublicImage('character_creation/eyes_color/male_realistic_green.png') },
  ],
  'artistic_male': [
    { id: 'brown', name: 'Brown', asset: getPublicImage('character_creation/eyes_color/male_artistic_brown.png') },
    { id: 'blue', name: 'Blue', asset: getPublicImage('character_creation/eyes_color/male_artistic_blue.png') },
    { id: 'green', name: 'Green', asset: getPublicImage('character_creation/eyes_color/male_artistic_green.png') },
    { id: 'yellow', name: 'Yellow', asset: getPublicImage('character_creation/eyes_color/male_artistic_yellow.png') },
    { id: 'red', name: 'Red', asset: getPublicImage('character_creation/eyes_color/male_artistic_red.png') },
  ],

  'realistic_futa': [
    { id: 'brown', name: 'Brown', asset: getPublicImage('character_creation/eyes_color/realistic_brown_v2.png') },
    { id: 'blue', name: 'Blue', asset: getPublicImage('character_creation/eyes_color/realistic_blue.png') },
    { id: 'green', name: 'Green', asset: getPublicImage('character_creation/eyes_color/realistic_green.png') },
  ],
  'artistic_futa': [
    { id: 'brown', name: 'Brown', asset: getPublicImage('character_creation/eyes_color/artistic_brown.png') },
    { id: 'blue', name: 'Blue', asset: getPublicImage('character_creation/eyes_color/artistic_blue.png') },
    { id: 'green', name: 'Green', asset: getPublicImage('character_creation/eyes_color/artistic_green.png') },
    { id: 'yellow', name: 'Yellow', asset: getPublicImage('character_creation/eyes_color/artistic_yellow.png') },
    { id: 'red', name: 'Red', asset: getPublicImage('character_creation/eyes_color/artistic_red.png') },
  ],
};

// =================================== Hair Styles ===================================
const ALL_FEMALE_HAIRSTYLES_REALISTIC: HairStyle[] = [
  { id: 'straight', name: 'Straight', asset: getPublicImage('character_creation/hair/realistic_straight_v2.png') },
  { id: 'bangs', name: 'Bangs', asset: getPublicImage('character_creation/hair/realistic_bangs_v2.png') },
  { id: 'curly', name: 'Curly', asset: getPublicImage('character_creation/hair/realistic_curly_v2.png') },
  { id: 'bun', name: 'Bun', asset: getPublicImage('character_creation/hair/realistic_bun_v2.png') },
  { id: 'short', name: 'Short', asset: getPublicImage('character_creation/hair/realistic_short_v2.png') },
  { id: 'braids', name: 'Braids', asset: getPublicImage('character_creation/hair/realistic_braids_v2.png') },
  { id: 'ponytail', name: 'Ponytail', asset: getPublicImage('character_creation/hair/realistic_ponytail_v2.png') },
  { id: 'razor', name: 'Razor Cut', asset: getPublicImage('character_creation/hair/realistic_razor_v2.png') },
  { id: 'mohawk', name: 'Mohawk', asset: getPublicImage('character_creation/hair/realistic_mohawk_v2.png') },
  { id: 'twa', name: 'TWA', asset: getPublicImage('character_creation/hair/realistic_twa_v2.png') },
];
const ALL_FEMALE_HAIRSTYLES_ALISTIC: HairStyle[] = [
  { id: 'straight', name: 'Straight', asset: getPublicImage('character_creation/hair/artistic_straight_v3.png') },
  { id: 'bangs', name: 'Bangs', asset: getPublicImage('character_creation/hair/artistic_bangs_v3.png') },
  { id: 'curly', name: 'Curly', asset: getPublicImage('character_creation/hair/artistic_curly_v3.png') },
  { id: 'bun', name: 'Bun', asset: getPublicImage('character_creation/hair/artistic_bun_v3.png') },
  { id: 'short', name: 'Short', asset: getPublicImage('character_creation/hair/artistic_short_v3.png') },
  { id: 'braids', name: 'Braids', asset: getPublicImage('character_creation/hair/artistic_braids_v3.png') },
  { id: 'ponytail', name: 'Ponytail', asset: getPublicImage('character_creation/hair/artistic_ponytail_v3.png') },
  { id: 'pigtails', name: 'Pigtails', asset: getPublicImage('character_creation/hair/artistic_pigtails_v3.png') },
  { id: 'spiky', name: 'Spiky', asset: getPublicImage('character_creation/hair/artistic_spiky_v3.png') },
  { id: 'razor', name: 'Razor Cut', asset: getPublicImage('character_creation/hair/artistic_razor_v3.png') },
];
const ALL_MALE_HAIRSTYLES_REALISTIC: HairStyle[] = [
  { id: 'bald', name: 'Bald', asset: getPublicImage('character_creation/hair/male_realistic_bald.png') },
  { id: 'boxer', name: 'Boxer', asset: getPublicImage('character_creation/hair/male_realistic_boxer.png') },
  { id: 'shaggy', name: 'Shaggy', asset: getPublicImage('character_creation/hair/male_realistic_shaggy.png') },
  { id: 'dreadlocks', name: 'Dread Locks', asset: getPublicImage('character_creation/hair/male_realistic_dreadlocks.png') },
  { id: 'man_bun', name: 'Man Bun', asset: getPublicImage('character_creation/hair/male_realistic_man_bun.png') },
  { id: 'medium_length', name: 'Medium Length', asset: getPublicImage('character_creation/hair/male_realistic_medium_length.png') },
  { id: 'mohawk', name: 'Mohawk', asset: getPublicImage('character_creation/hair/male_realistic_mohawk.png') },
  { id: 'long', name: 'Long', asset: getPublicImage('character_creation/hair/male_realistic_long.png') },
];
const ALL_MALE_HAIRSTYLES_ARTISTIC: HairStyle[] = [
  { id: 'bald', name: 'Bald', asset: getPublicImage('character_creation/hair/male_artistic_bald.png') },
  { id: 'boxer', name: 'Boxer', asset: getPublicImage('character_creation/hair/male_artistic_boxer.png') },
  { id: 'shaggy', name: 'Shaggy', asset: getPublicImage('character_creation/hair/male_artistic_shaggy.png') },
  { id: 'dreadlocks', name: 'Dread Locks', asset: getPublicImage('character_creation/hair/male_artistic_dreadlocks.png') },
  { id: 'man_bun', name: 'Man Bun', asset: getPublicImage('character_creation/hair/male_artistic_man_bun.png') },
  { id: 'medium_length', name: 'Medium Length', asset: getPublicImage('character_creation/hair/male_artistic_medium_length.png') },
  { id: 'mohawk', name: 'Mohawk', asset: getPublicImage('character_creation/hair/male_artistic_mohawk.png') },
  { id: 'long', name: 'Long', asset: getPublicImage('character_creation/hair/male_artistic_long.png') },
];
export const hairStyles: { [key: string]: HairStyle[] } = {
  'realistic_female': ALL_FEMALE_HAIRSTYLES_REALISTIC,
  'artistic_female': ALL_FEMALE_HAIRSTYLES_ALISTIC,
  'realistic_male': ALL_MALE_HAIRSTYLES_REALISTIC,
  'artistic_male': ALL_MALE_HAIRSTYLES_ARTISTIC,  
  'realistic_futa': ALL_FEMALE_HAIRSTYLES_REALISTIC,
  'artistic_futa': ALL_FEMALE_HAIRSTYLES_ALISTIC,
};

// =================================== Hair Color ===================================
const ALL_HAIR_COLORS: HairColor[] = [
  { id: 'blonde', name: 'Blonde', asset: '#FFE5B4' },
  { id: 'brunette', name: 'Brunette', asset: '#4A3728' },
  { id: 'black', name: 'Black', asset: '#0F0F0F' },
  { id: 'redhead', name: 'Redhead', asset: '#D44939' },
  { id: 'pink', name: 'Pink', asset: '#FF69B4' },
  { id: 'white', name: 'White', asset: '#FFFFFF' },
  { id: 'blue', name: 'Blue', asset: '#4169E1' },
  { id: 'green', name: 'Green', asset: '#228B22' },
  { id: 'purple', name: 'Purple', asset: '#800080' },
  { id: 'multicolor', name: 'Multicolor', asset: 'linear-gradient(45deg, #FF0000, #00FF00, #0000FF)' },
];
export const hairColors: { [key: string]: HairColor[] } = {
  'realistic_female': ALL_HAIR_COLORS,
  'artistic_female': ALL_HAIR_COLORS,
  'realistic_male': ALL_HAIR_COLORS,
  'artistic_male': ALL_HAIR_COLORS,
  'realistic_futa': ALL_HAIR_COLORS,
  'artistic_futa': ALL_HAIR_COLORS,
};

// =================================== Body Type ===================================
export const bodyTypes: { [key: string]: BodyType[] } = {
  'realistic_female': [
    { id: 'slim', name: 'Slim', asset: getPublicImage('character_creation/body/realistic_body_slim.png') },
    { id: 'petite', name: 'Petite', asset: getPublicImage('character_creation/body/realistic_body_petite.png') },
    { id: 'curvy', name: 'Curvy', asset: getPublicImage('character_creation/body/realistic_body_curvy_v2.png') },
    { id: 'voluptuous', name: 'Voluptuous', asset: getPublicImage('character_creation/body/realistic_body_voluptuous.png') },
    { id: 'athletic', name: 'Athletic', asset: getPublicImage('character_creation/body/realistic_body_athletic.png') },
  ],
  'artistic_female': [
    { id: 'slim', name: 'Slim', asset: getPublicImage('character_creation/body/artistic_body_slim.png') },
    { id: 'petite', name: 'Petite', asset: getPublicImage('character_creation/body/artistic_body_petite.png') },
    { id: 'curvy', name: 'Curvy', asset: getPublicImage('character_creation/body/artistic_body_curvy_v2.png') },
    { id: 'voluptuous', name: 'Voluptuous', asset: getPublicImage('character_creation/body/artistic_body_voluptuous.png') },
    { id: 'athletic', name: 'Athletic', asset: getPublicImage('character_creation/body/artistic_body_athletic.png') },
  ],

  'realistic_male': [
    { id: 'average', name: 'Average build', asset: getPublicImage('character_creation/body/male_realistic_body_average.png') },
    { id: 'slender', name: 'Slender', asset: getPublicImage('character_creation/body/male_realistic_body_slender.png') },
    { id: 'athletic', name: 'Athletic', asset: getPublicImage('character_creation/body/male_realistic_body_athletic.png') },
    { id: 'muscular', name: 'Muscular', asset: getPublicImage('character_creation/body/male_realistic_body_muscular.png') },
    { id: 'chubby', name: 'Chubby', asset: getPublicImage('character_creation/body/male_realistic_body_chubby.png') },
  ],
  'artistic_male': [
    { id: 'average', name: 'Average build', asset: getPublicImage('character_creation/body/male_artistic_body_average.png') },
    { id: 'slender', name: 'Slender', asset: getPublicImage('character_creation/body/male_artistic_body_slender.png') },
    { id: 'athletic', name: 'Athletic', asset: getPublicImage('character_creation/body/male_artistic_body_athletic.png') },
    { id: 'muscular', name: 'Muscular', asset: getPublicImage('character_creation/body/male_artistic_body_muscular.png') },
    { id: 'chubby', name: 'Chubby', asset: getPublicImage('character_creation/body/male_artistic_body_chubby.png') },
  ],

  'realistic_futa': [
    { id: 'slim', name: 'Slim', asset: getPublicImage('character_creation/body/realistic_body_slim.png') },
    { id: 'petite', name: 'Petite', asset: getPublicImage('character_creation/body/realistic_body_petite.png') },
    { id: 'curvy', name: 'Curvy', asset: getPublicImage('character_creation/body/realistic_body_curvy_v2.png') },
    { id: 'voluptuous', name: 'Voluptuous', asset: getPublicImage('character_creation/body/realistic_body_voluptuous.png') },
    { id: 'athletic', name: 'Athletic', asset: getPublicImage('character_creation/body/realistic_body_athletic.png') },
  ],
  'artistic_futa': [
    { id: 'slim', name: 'Slim', asset: getPublicImage('character_creation/body/artistic_body_slim.png') },
    { id: 'petite', name: 'Petite', asset: getPublicImage('character_creation/body/artistic_body_petite.png') },
    { id: 'curvy', name: 'Curvy', asset: getPublicImage('character_creation/body/artistic_body_curvy_v2.png') },
    { id: 'voluptuous', name: 'Voluptuous', asset: getPublicImage('character_creation/body/artistic_body_voluptuous.png') },
    { id: 'athletic', name: 'Athletic', asset: getPublicImage('character_creation/body/artistic_body_athletic.png') },
  ],
};

// =================================== Breast Size ===================================
export const breastSizes: { [key: string]: BreastSize[] } = {
  'realistic_female': [
    { id: 'flat', name: 'Flat', asset: getPublicImage('character_creation/breasts/realistic_breasts_flat.png') },
    { id: 'small', name: 'Small', asset: getPublicImage('character_creation/breasts/realistic_breasts_small.png') },
    { id: 'medium', name: 'Medium', asset: getPublicImage('character_creation/breasts/realistic_breasts_medium.png') },
    { id: 'large', name: 'Large', asset: getPublicImage('character_creation/breasts/realistic_breasts_large.png') },
    { id: 'huge', name: 'Huge', asset: getPublicImage('character_creation/breasts/realistic_breasts_huge.png') },
  ],
  'artistic_female': [
    { id: 'flat', name: 'Flat', asset: getPublicImage('character_creation/breasts/artistic_breasts_flat.png') },
    { id: 'small', name: 'Small', asset: getPublicImage('character_creation/breasts/artistic_breasts_small.png') },
    { id: 'medium', name: 'Medium', asset: getPublicImage('character_creation/breasts/artistic_breasts_medium.png') },
    { id: 'large', name: 'Large', asset: getPublicImage('character_creation/breasts/artistic_breasts_large.png') },
    { id: 'huge', name: 'Huge', asset: getPublicImage('character_creation/breasts/artistic_breasts_huge.png') },
  ],

  'realistic_male': [
  ],
  'artistic_male': [
  ],

  'realistic_futa': [
    { id: 'flat', name: 'Flat', asset: getPublicImage('character_creation/breasts/realistic_breasts_flat.png') },
    { id: 'small', name: 'Small', asset: getPublicImage('character_creation/breasts/realistic_breasts_small.png') },
    { id: 'medium', name: 'Medium', asset: getPublicImage('character_creation/breasts/realistic_breasts_medium.png') },
    { id: 'large', name: 'Large', asset: getPublicImage('character_creation/breasts/realistic_breasts_large.png') },
    { id: 'huge', name: 'Huge', asset: getPublicImage('character_creation/breasts/realistic_breasts_huge.png') },
  ],
  'artistic_futa': [
    { id: 'flat', name: 'Flat', asset: getPublicImage('character_creation/breasts/artistic_breasts_flat.png') },
    { id: 'small', name: 'Small', asset: getPublicImage('character_creation/breasts/artistic_breasts_small.png') },
    { id: 'medium', name: 'Medium', asset: getPublicImage('character_creation/breasts/artistic_breasts_medium.png') },
    { id: 'large', name: 'Large', asset: getPublicImage('character_creation/breasts/artistic_breasts_large.png') },
    { id: 'huge', name: 'Huge', asset: getPublicImage('character_creation/breasts/artistic_breasts_huge.png') },
  ],
};

// =================================== Butt Size ===================================
export const buttSizes: { [key: string]: ButtSize[] } = {
  'realistic_female': [
    { id: 'skinny', name: 'Skinny', asset: getPublicImage('character_creation/butt/realistic_butt_skinny.png') },
    { id: 'small', name: 'Small', asset: getPublicImage('character_creation/butt/realistic_butt_small.png') },
    { id: 'medium', name: 'Medium', asset: getPublicImage('character_creation/butt/realistic_butt_medium.png') },
    { id: 'large', name: 'Large', asset: getPublicImage('character_creation/butt/realistic_butt_large.png') },
    { id: 'athletic', name: 'Athletic', asset: getPublicImage('character_creation/butt/realistic_butt_athletic.png') },
  ],
  'artistic_female': [
    { id: 'skinny', name: 'Skinny', asset: getPublicImage('character_creation/butt/artistic_butt_skinny.png') },
    { id: 'small', name: 'Small', asset: getPublicImage('character_creation/butt/artistic_butt_small.png') },
    { id: 'medium', name: 'Medium', asset: getPublicImage('character_creation/butt/artistic_butt_medium.png') },
    { id: 'large', name: 'Large', asset: getPublicImage('character_creation/butt/artistic_butt_large.png') },
    { id: 'athletic', name: 'Athletic', asset: getPublicImage('character_creation/butt/artistic_butt_athletic.png') },
  ],

  'realistic_male': [
  ],
  'artistic_male': [
  ],

  'realistic_futa': [
    { id: 'skinny', name: 'Skinny', asset: getPublicImage('character_creation/butt/realistic_butt_skinny.png') },
    { id: 'small', name: 'Small', asset: getPublicImage('character_creation/butt/realistic_butt_small.png') },
    { id: 'medium', name: 'Medium', asset: getPublicImage('character_creation/butt/realistic_butt_medium.png') },
    { id: 'large', name: 'Large', asset: getPublicImage('character_creation/butt/realistic_butt_large.png') },
    { id: 'athletic', name: 'Athletic', asset: getPublicImage('character_creation/butt/realistic_butt_athletic.png') },
  ],
  'artistic_futa': [
    { id: 'skinny', name: 'Skinny', asset: getPublicImage('character_creation/butt/artistic_butt_skinny.png') },
    { id: 'small', name: 'Small', asset: getPublicImage('character_creation/butt/artistic_butt_small.png') },
    { id: 'medium', name: 'Medium', asset: getPublicImage('character_creation/butt/artistic_butt_medium.png') },
    { id: 'large', name: 'Large', asset: getPublicImage('character_creation/butt/artistic_butt_large.png') },
    { id: 'athletic', name: 'Athletic', asset: getPublicImage('character_creation/butt/artistic_butt_athletic.png') },
  ],
};

// =================================== Clothes ===================================
const ALL_FEMALE_CLOTHES: Clothing[] = [
  { id: 'lingerie', name: 'Lingerie', asset: '🩱' },
  { id: 'swimwear', name: 'Swimwear', asset: '👙' },
  { id: 'clubwear', name: 'Clubwear', asset: '💃' },
  { id: 'formal', name: 'Formal', asset: '👗' },
  { id: 'businessSexy', name: 'Business Sexy', asset: '👠' },
  { id: 'leather', name: 'Leather', asset: '🧥' },
  { id: 'lace', name: 'Lace', asset: '🕸️' },
  { id: 'corset', name: 'Corset', asset: '👘' },
  { id: 'minidress', name: 'Mini Dress', asset: '👚' },
  { id: 'bodysuit', name: 'Bodysuit', asset: '🩲' },
  { id: 'schoolgirl', name: 'Schoolgirl', asset: '🎒' },
  { id: 'maid', name: 'Maid', asset: '🧹' },
  { id: 'nurse', name: 'Nurse', asset: '👩‍⚕️' },
  { id: 'catsuit', name: 'Catsuit', asset: '🐱' },
  { id: 'bikini', name: 'Bikini', asset: '🌊' }
];
const ALL_MALE_CLOTHES: Clothing[] = [
  { id: 'suit', name: 'Business Suit', asset: '🕴️', description: 'wearing a fitted business suit with an open collar' },
  { id: 'casual', name: 'Shirt & Jeans', asset: '👕', description: 'wearing a fitted shirt and dark jeans' },
  { id: 'leather', name: 'Leather Jacket', asset: '🧥', description: 'wearing a leather jacket over a t-shirt' },
  { id: 'doctor', name: 'Doctor\'s Coat', asset: '👨‍⚕️', description: 'wearing a white doctor coat with scrubs' },
  { id: 'professor', name: 'Professor', asset: '👨‍🏫', description: 'wearing a blazer with jeans' },
  { id: 'gym', name: 'Gym Wear', asset: '🏋️', description: 'wearing fitted gym clothes' },
  { id: 'formal', name: 'Tuxedo', asset: '🤵', description: 'wearing a black fitted tuxedo' },
  { id: 'firefighter', name: 'Firefighter', asset: '👨‍🚒', description: 'wearing firefighter uniform with suspenders' },
  { id: 'boxers', name: 'Boxers', asset: '🩲', description: 'wearing just boxers, showing muscular physique' },
  { id: 'sweater', name: 'Cozy Sweater', asset: '🧶', description: 'wearing a soft knit sweater' },
  { id: 'swim', name: 'Swim Shorts', asset: '🏊', description: 'wearing fitted swim shorts' },
  { id: 'sweatpants', name: 'Sweatpants', asset: '🌙', description: 'wearing grey sweatpants, no shirt' },
];
export const clothes: { [key: string]: Clothing[] } = {
  'realistic_female': ALL_FEMALE_CLOTHES,
  'artistic_female': ALL_FEMALE_CLOTHES,
  'realistic_male': ALL_MALE_CLOTHES,
  'artistic_male': ALL_MALE_CLOTHES,
  'realistic_futa': ALL_FEMALE_CLOTHES,
  'artistic_futa': ALL_FEMALE_CLOTHES,
};

// =================================== Personalities ===================================
const ALL_FEMALE_PERSONALITIES: Personality[] = [
  { id: 'Caregiver', name: 'Caregiver', description: 'Nurturing, protective, and always there to offer comfort.', asset: '🤗' },
  { id: 'Innocent', name: 'Innocent', description: 'Optimistic, naive, and sees world with wonder.', asset: '😇' },
  { id: 'Lover', name: 'Lover', description: 'Romantic, affectionate, and cherishes deep emotional connection.', asset: '❤️' },
  { id: 'Submissive', name: 'Submissive', description: 'Obedient, yielding, and happy to follow.', asset: '🙇‍♀️' },
  { id: 'Dominant', name: 'Dominant', description: 'Assertive, controlling, and commanding.', asset: '👑' },
  { id: 'Temptress', name: 'Temptress', description: 'Flirtatious, playful, and always leaving you wanting more.', asset: '️💋' },
  { id: 'Nympho', name: 'Nympho', description: 'Intensely enthusiastic, sensual, and always ready for intimacy.', asset: '💦' },
  { id: 'Mean', name: 'Mean', description: 'Cold, dismissive, and often sarcastic.', asset: '😡' },
  { id: 'Diva', name: 'Diva', description: 'High-maintenance, demanding, and expects to be treated like royalty.', asset: '👸' },
  { id: 'Tsundere', name: 'Tsundere', description: 'Hostile and dismissive at first, but gradually warms up... maybe.', asset: '😤' },
  { id: 'Insane', name: 'Insane', description: 'Unpredictable, chaotic, and detached from reality. Proceed with caution.', asset: '🤪' },
  { id: 'Yandere', name: 'Yandere', description: 'Sweet and loving on the surface, but possessive and potentially dangerous.', asset: '🔪' }
];
const ALL_MALE_PERSONALITIES: Personality[] = [
  { id: 'Protector', name: 'Protector', description: "Like a personal guardian, he's always ready to stand up for his friends.", asset: '🛡️' },
  { id: 'Sage', name: 'Sage', description: "He's the \"wise old man\" in a young person's body, always knowing what to say.", asset: '🧠' },
  { id: 'Hero', name: 'Hero', description: "Brave and bold, he's the guy who's not afraid to stand up for what's right.", asset: '🦸‍♂️' },
  { id: 'Jester', name: 'Jester', description: "He's the class clown, always cracking jokes and making everyone laugh.", asset: '🃏' },
  { id: 'ToyBoy', name: 'Toy Boy', description: "He's got a way with flirting that seems to charm just about anyone.", asset: '🎯' },
  { id: 'Dominant', name: 'Dominant', description: "Takes the lead in any dance of life, always at the front of the pack.", asset: '👑' },
  { id: 'Submissive', name: 'Submissive', description: "Happy to follow and let others light the path, content in the supporting role.", asset: '🙇‍♂️' },
  { id: 'Lover', name: 'Lover', description: "He's all about romance, like he stepped out of a Valentine's card.", asset: '❤️' },
  { id: 'Beast', name: 'Beast', description: "Tough and competitive, he loves to win and never gives up.", asset: '🐺' },
  { id: 'Confidant', name: 'Confidant', description: "He's the secret-keeper, the one everyone trusts with their gossip and worries.", asset: '🤫' },
  { id: 'Rebel', name: 'Rebel', description: "He likes to break the rules and do things his own unique way.", asset: '✊' },
  { id: 'Scholar', name: 'Scholar', description: "He's a walking encyclopedia, always buried in books and acing the tests.", asset: '🎓' }
];
export const personalities: { [key: string]: Personality[] } = {
  'realistic_female': ALL_FEMALE_PERSONALITIES,
  'artistic_female': ALL_FEMALE_PERSONALITIES,
  'realistic_male': ALL_MALE_PERSONALITIES,
  'artistic_male': ALL_MALE_PERSONALITIES,
  'realistic_futa': ALL_FEMALE_PERSONALITIES,
  'artistic_futa': ALL_FEMALE_PERSONALITIES,
}

// =================================== Voices ===================================
const ALL_FEMALE_VOICES: Voice[] = [
  { id: '1', name: 'Voice 1', asset: null },
  { id: '5', name: 'Voice 2', asset: null },
  { id: '3', name: 'Voice 3', asset: null },
  { id: '4', name: 'Voice 4', asset: null },
  { id: '2', name: 'Voice 5', asset: null },
];
const ALL_MALE_VOICES: Voice[] = [
  { id: '36', name: 'Voice 1', asset: null },
  { id: '37', name: 'Voice 2', asset: null },
  { id: '38', name: 'Voice 3', asset: null },
  { id: '39', name: 'Voice 4', asset: null },
  { id: '40', name: 'Voice 5', asset: null },
];
export const voices: { [key: string]: Voice[] } = {
  'realistic_female': ALL_FEMALE_VOICES,
  'artistic_female': ALL_FEMALE_VOICES,
  'realistic_male': ALL_MALE_VOICES,
  'artistic_male': ALL_MALE_VOICES,
  'realistic_futa': ALL_FEMALE_VOICES,
  'artistic_futa': ALL_FEMALE_VOICES,
}


// =================================== Relationships ===================================
const ALL_FEMALE_RELATIONSHIPS: Relationship[] = [
  { id: 'stranger', name: 'Stranger', asset: '🕵️‍♀️' },
  { id: 'classmate', name: 'Classmate', asset: '📚' },
  { id: 'coworker', name: 'Co-worker', asset: '💼' },
  { id: 'teacher', name: 'Teacher', asset: '👩‍🏫' },
  { id: 'girlfriend', name: 'Girlfriend', asset: '🩷' },
  { id: 'fwb', name: 'Friends with Benefits', asset: '🔥' },
  { id: 'wife', name: 'Wife', asset: '💍' },
  { id: 'mistress', name: 'Mistress', asset: '🌙' },
  { id: 'acquaintance', name: 'Acquaintance', asset: '🤝' },
  { id: 'bestfriend', name: 'Best Friend', asset: '🤞' },
  { id: 'stepsister', name: 'Step Sister', asset: '👯‍♀️' },
  { id: 'stepmom', name: 'Step Mom', asset: '👩‍👦' },
];
const ALL_MALE_RELATIONSHIPS: Relationship[] = [
  { id: 'stranger', name: 'Stranger', asset: '🕵️‍♀️' },
  { id: 'classmate', name: 'Classmate', asset: '📚' },
  { id: 'coworker', name: 'Co-worker', asset: '💼' },
  { id: 'teacher', name: 'Teacher', asset: '👩‍🏫' },
  { id: 'boyfriend', name: 'Boyfriend', asset: '🩷' },
  { id: 'fwb', name: 'Friends with Benefits', asset: '🔥' },
  { id: 'husband', name: 'Husband', asset: '💍' },
  { id: 'lover', name: 'Lover', asset: '🌙' },
  { id: 'acquaintance', name: 'Acquaintance', asset: '🤝' },
  { id: 'bestfriend', name: 'Best Friend', asset: '🤞' },
  { id: 'stepbrother', name: 'Step Brother', asset: '👯‍♀️' },
  { id: 'stepdad', name: 'Step Dad', asset: '👩‍👦' },
];
export const relationships: { [key: string]: Relationship[] } = {
  'realistic_female': ALL_FEMALE_RELATIONSHIPS,
  'artistic_female': ALL_FEMALE_RELATIONSHIPS,
  'realistic_male': ALL_MALE_RELATIONSHIPS,
  'artistic_male': ALL_MALE_RELATIONSHIPS,
  'realistic_futa': ALL_FEMALE_RELATIONSHIPS,
  'artistic_futa': ALL_FEMALE_RELATIONSHIPS,
};


// =================================== Facial Expressions ===================================
const ALL_FEMALE_FACIAL_EXPRESSIONS: FacialExpression[] = [
  { id: 'angry', name: 'Angry', asset: '😠', description: 'angry,' },
  { id: 'happy', name: 'Happy', asset: '😊', description: 'happy,' },
  { id: 'teasing', name: 'Teasing', asset: '😍', description: 'naughty and teasing facial expression,' },
  { id: 'shocked', name: 'Shocked', asset: '😲', description: 'shocked facial expression,' },
  { id: 'moaning', name: 'Moaning', asset: '🥵', description: 'moaning, eyes closed,' },
  { id: 'ahegao', name: 'Ahegao', asset: '🥵', description: '(ahegao),' },  
];
const ALL_MALE_FACIAL_EXPRESSIONS: FacialExpression[] = [
  { id: 'stern', name: 'Stern', asset: '😠', description: 'stern and serious expression,' },
  { id: 'smirk', name: 'Smirking', asset: '😏', description: 'confident smirk,' },
  { id: 'intense', name: 'Intense', asset: '😈', description: 'intense, lustful gaze,' },
  { id: 'dominant', name: 'Dominant', asset: '😤', description: 'dominant, commanding expression,' },
  { id: 'pleased', name: 'Pleased', asset: '😌', description: 'pleased, satisfied expression,' },
  { id: 'passionate', name: 'Passionate', asset: '🥵', description: 'passionate, eyes half-closed,' },
];
export const facialExpressions: { [key: string]: FacialExpression[] } = {
  'realistic_female': ALL_FEMALE_FACIAL_EXPRESSIONS,
  'artistic_female': ALL_FEMALE_FACIAL_EXPRESSIONS,
  'realistic_male': ALL_MALE_FACIAL_EXPRESSIONS,
  'artistic_male': ALL_MALE_FACIAL_EXPRESSIONS,
  'realistic_futa': ALL_FEMALE_FACIAL_EXPRESSIONS,
  'artistic_futa': ALL_FEMALE_FACIAL_EXPRESSIONS,
}

const ALL_FEMALE_PHYSICAL_POSITIONS: PhysicalPosition[] = [
  { id: 'lying down', name: 'Lying Down', asset: '🛏️', description: 'lying down,' },
  { id: 'kneeling', name: 'Kneeling', asset: '🧎‍♀️', description: 'kneeling,' },
  { id: 'sitting', name: 'Sitting', asset: '🪑', description: 'sitting,' },
  { id: 'standing', name: 'Standing', asset: '🧍‍♀️', description: 'standing,' },
  { id: 'sex', name: 'Having Sex', asset: '💋', description: 'having sex,' },
  { id: 'blowjob', name: 'Blowjob', asset: '☝️', description: 'blowjob,' },
  { id: 'masturbating', name: 'Masturbating', asset: '☝️', description: 'masturbating,' },
  { id: 'doggy style', name: 'Doggy Style', asset: '☝️', description: 'doggy style sex,' },
  { id: 'anal', name: 'Anal Sex', asset: '☝️', description: 'anal sex,' },
  { id: 'bound and gagged', name: 'Bound and Gagged', asset: '☝️', description: 'arms tied behind back, mouth gagged,' },
  { id: 'cumshot', name: 'Cumshot', asset: '☝️', description: 'cum on face,' },
];
const ALL_MALE_PHYSICAL_POSITIONS: PhysicalPosition[] = [
  { id: 'lying down', name: 'Lying Down', asset: '🛏️', description: 'lying down,' },
  { id: 'kneeling', name: 'Kneeling', asset: '🧎‍♀️', description: 'kneeling,' },
  { id: 'sitting', name: 'Sitting', asset: '🪑', description: 'sitting,' },
  { id: 'standing', name: 'Standing', asset: '🧍‍♀️', description: 'standing,' },
  { id: 'sex', name: 'Having Sex', asset: '💋', description: 'having sex,' },
  { id: 'blowjob', name: 'Blowjob', asset: '☝️', description: 'blowjob,' },
  { id: 'masturbating', name: 'Masturbating', asset: '☝️', description: 'masturbating,' },
  { id: 'doggy style', name: 'Doggy Style', asset: '☝️', description: 'doggy style sex,' },
  { id: 'anal', name: 'Anal Sex', asset: '☝️', description: 'anal sex,' },
  { id: 'bound and gagged', name: 'Bound and Gagged', asset: '☝️', description: 'arms tied behind back, mouth gagged,' },
  { id: 'cumshot', name: 'Cumshot', asset: '☝️', description: 'cum on face,' },
];
export const physicalPositions: { [key: string]: PhysicalPosition[] } = {
  'realistic_female': ALL_FEMALE_PHYSICAL_POSITIONS,
  'artistic_female': ALL_FEMALE_PHYSICAL_POSITIONS,
  'realistic_male': ALL_MALE_PHYSICAL_POSITIONS,
  'artistic_male': ALL_MALE_PHYSICAL_POSITIONS,
  'realistic_futa': ALL_FEMALE_PHYSICAL_POSITIONS,
  'artistic_futa': ALL_FEMALE_PHYSICAL_POSITIONS,
};

const ALL_VIEW_ANGLES: CharacterTrait[] = [
  { id: 'medium shot', name: 'Medium Shot', asset: '🛏️', description: 'medium shot, dynamic angle,' },
  { id: 'from above', name: 'From Above', asset: '🧎‍♀️', description: 'view from above,' },
  { id: 'from behind', name: 'From Behind', asset: '🪑', description: 'from from behind,' },
  { id: 'fullbody', name: 'Full Body Shot', asset: '🧍‍♀️', description: 'full body view,' },
];
export const viewAngles: { [key: string]: CharacterTrait[] } = {
  'realistic_female': ALL_VIEW_ANGLES,
  'artistic_female': ALL_VIEW_ANGLES,
  'realistic_male': ALL_VIEW_ANGLES,
  'artistic_male': ALL_VIEW_ANGLES,
  'realistic_futa': ALL_VIEW_ANGLES,
  'artistic_futa': ALL_VIEW_ANGLES,
};

const ALL_ENVIRONMENTS: CharacterTrait[] = [
  { id: 'Bedroom', name: 'Bedroom', asset: '🛏️', description: 'in a bedroom,' },
  { id: 'Kitchen', name: 'Kitchen', asset: '🛁', description: 'in a kitchen,' },
  { id: 'Gym', name: 'Gym', asset: '🏋️‍♀️', description: 'in a gym,' },
  { id: 'Office', name: 'Office', asset: '�', description: 'in an office,' },
  { id: 'classroom', name: 'Classroom', asset: '🏫', description: 'in a school classroom,' },
  { id: 'Forest', name: 'Forest', asset: '🌲', description: 'in a forest,' },
  { id: 'Beach', name: 'Beach', asset: '🏖️', description: 'on a beach,' },
  { id: 'Warehouse', name: 'Warehouse', asset: '🏜️', description: 'in a dark abandoned warehouse,' },
];
export const environments: { [key: string]: CharacterTrait[] } = {
  'realistic_female': ALL_ENVIRONMENTS,
  'artistic_female': ALL_ENVIRONMENTS,
  'realistic_male': ALL_ENVIRONMENTS,
  'artistic_male': ALL_ENVIRONMENTS,
  'realistic_futa': ALL_ENVIRONMENTS,
  'artistic_futa': ALL_ENVIRONMENTS,
};

const ALL_FEMALE_CUSTOM_CLOTHES: CharacterTrait[] = [
  { id: 'lingerie', name: 'Lingerie', asset: '🩱', description: 'wearing lacy lingerie,' },
  { id: 'naked', name: 'Naked', asset: '🩱', description: 'naked,' },
  { id: 'topless', name: 'Topless', asset: '🩱', description: 'topless,' },
  { id: 'sundress', name: 'Sundress', asset: '🩱', description: 'wearing a sundress,' },
  { id: 'bikini', name: 'Bikini', asset: '🩱', description: 'wearing a bikini,' },
  { id: 'teacher', name: 'Teaching Outfit', asset: '🩱', description: 'wearing a pencil skirt and a white blouse, teaching in front of the class,' },
  { id: 'sport', name: 'Sporty', asset: '🩱', description: 'wearing sporty clothing, sweaty,' },
  { id: 'shibari', name: 'Shibari', asset: '🩱', description: 'wearing shibari outfit,' },
  { id: 'schoolgirl', name: 'School Girl', asset: '🩱', description: 'wearing schoolgirl outfit,' },
  { id: 'corset', name: 'Corset', asset: '🩱', description: 'wearing a corset,' },
];
const ALL_MALE_CUSTOM_CLOTHES: CharacterTrait[] = [
  { id: 'suit', name: 'Business Suit', asset: '🕴️', description: 'wearing a fitted business suit,' },
  { id: 'casual', name: 'Casual Wear', asset: '👕', description: 'wearing casual clothes,' },
  { id: 'shirtless', name: 'Shirtless', asset: '💪', description: 'shirtless, muscular,' },
  { id: 'swimwear', name: 'Swimwear', asset: '🩳', description: 'wearing swim trunks,' },
  { id: 'gym', name: 'Gym Wear', asset: '🏋️', description: 'wearing gym clothes, sweaty,' },
  { id: 'formal', name: 'Tuxedo', asset: '🤵', description: 'wearing a black tuxedo,' },
  { id: 'leather', name: 'Leather Jacket', asset: '🧥', description: 'wearing a leather jacket,' },
  { id: 'uniform', name: 'Army uniform', asset: '👮', description: 'wearing a military uniform,' },
  { id: 'doctor', name: 'Doctor Coat', asset: '👨‍⚕️', description: 'wearing a white doctor coat,' },
  { id: 'professor', name: 'Professor Attire', asset: '👨‍🏫', description: 'wearing professor clothes with glasses,' }
];
export const customClothes: { [key: string]: CharacterTrait[] } = {
  'realistic_female': ALL_FEMALE_CUSTOM_CLOTHES,
  'artistic_female': ALL_FEMALE_CUSTOM_CLOTHES,
  'realistic_male': ALL_MALE_CUSTOM_CLOTHES,
  'artistic_male': ALL_MALE_CUSTOM_CLOTHES,
  'realistic_futa': ALL_FEMALE_CUSTOM_CLOTHES,
  'artistic_futa': ALL_FEMALE_CUSTOM_CLOTHES,
};

const FEMALE_PROMPT_EXAMPLES = {
  'Dinner date': 'wearing a pencil dress, sitting at a dinner table at a fancy restaurant, dim lighting',
  'Wet and wild': 'wearing bikini, sweaty, dripping wet, standing, at the beach',
  'Sexy time': 'naked, lying on back, vaginal sex, oiled up, eyes closed, moaning, in bedroom',
  'Write your own': '',
};
const MALE_PROMPT_EXAMPLES = {
  'Gym Beast': 'shirtless, intense workout, massive muscles pumped, dripping sweat, doing heavy deadlifts, veins showing',
  'Power Suit': 'wearing expensive suit, commanding presence, in corporate boardroom, making power moves, assertive stance',
  'Wild Night': 'ripped physique, dominating presence, pressed against wall, rough passionate encounter, strong grip, dark club setting',
  'Write your own': '',
};
const FUTA_PROMPT_EXAMPLES = {
  'Dinner date': 'wearing a pencil dress, sitting at a dinner table at a fancy restaurant, dim lighting',
  'Wet and wild': 'wearing bikini, sweaty, dripping wet, standing, at the beach',
  'Sexy time': 'naked, lying on back, vaginal sex, oiled up, eyes closed, moaning, in bedroom',
  'Write your own': '',
};
export const promptExamples: { [key: string]: { [key: string]: string } } = {
  'realistic_female': FEMALE_PROMPT_EXAMPLES,
  'artistic_female': FEMALE_PROMPT_EXAMPLES,
  'realistic_male': MALE_PROMPT_EXAMPLES,
  'artistic_male': MALE_PROMPT_EXAMPLES,
  'realistic_futa': FUTA_PROMPT_EXAMPLES,
  'artistic_futa': FUTA_PROMPT_EXAMPLES,
};

type CharacterTraitList = CharacterTrait[];
type CharacterTraitDictionary = { [key: string]: CharacterTrait[] };

const choicesByName: { [key: string]: CharacterTraitList | CharacterTraitDictionary | null} = {
  'gender': genders,
  'style': styles,
  'ethnicity': ethnicities,
  'age': ages,
  'eyesColor': eyesColors,
  'hairStyle': hairStyles,
  'hairColor': hairColors,
  'bodyType': bodyTypes,
  'breastSize': breastSizes,
  'buttSize': buttSizes,
  'personality': personalities,
  'relationship': relationships,
  'clothing': clothes,
  'voice': voices,
  'facialExpression': facialExpressions,
  'physicalPosition': physicalPositions,
  'customClothes': customClothes,
  'viewAngle': viewAngles,
  'environment': environments,
}

export const getCreationChoicesByName = (gender?: string, style?: string, choiceName?: keyof CharacterCreationChoices) => {
  if (!choiceName || !(choiceName in choicesByName)) {
    return null;
  }
  const choices = choicesByName[choiceName];
  if (Array.isArray(choices)) {
    return choices;
  }
  if (choices && typeof choices === 'object') {
    const key = `${style || 'realistic'}_${gender || 'female'}`;
    return choices[key] || null;
  }
  return null;
}

const ALL_FEMALE_NAMES = [
  "Luna", "Zoe", "Aria", "Nova", "Chloe", "Mia", "Ava", "Lily", "Emma", "Sophia",
  "Isla", "Everly", "Hazel", "Aurora", "Willow", "Ivy", "Sage", "Skye", "River", "Remi",
  "Quinn", "Piper", "Nora", "Zara", "Aspen", "Ember", "Juniper", "Sarah", "Rachel", "Hailey",
  "Maya", "Alice", "Ruby", "Violet", "Scarlett", "Bella", "Claire", "Grace", "Rose", "Victoria",
  "Jade", "Autumn", "Summer", "Winter", "Spring", "Dawn", "Eve", "Faith", "Hope", "Joy",
  "Melody", "Harmony", "Crystal", "Pearl", "Jasmine", "Holly", "Iris", "Daisy", "Flora", "Fiona"
];

const ALL_MALE_NAMES = [
  "Luca", "Finn", "Atlas", "Ash", "Sebastian", "Adrian", "Julian", "Dante", "Dominic",
  "Ryder", "Phoenix", "Axel", "Roman", "Leo", "Jasper", "Theo", "Milo", "Caleb", "Ezra",
  "Silas", "Asher", "Rowan", "Archer", "Zane", "Rhys", "Aiden", "Sage"
];

export const getCharacterNameSuggestion = (gender: string) => {
  const suggestions = gender === 'male' ? ALL_MALE_NAMES : ALL_FEMALE_NAMES;
  return suggestions[Math.floor(Math.random() * suggestions.length)];
};
