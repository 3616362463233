export interface UserProfile {
  username: string;
  bio: string;
  gender: ProfileGender;
  interested_gender: ProfileGender;
}

export interface UserSubscription {
  status: string;
  vendor: string;
  product_name?: string;
  product_id: number;
  current_period_start: Date;
  current_period_end: Date;
}

export function hasValidSubscription(subscription?: UserSubscription): boolean {
  if (!subscription) return false;

  const now = new Date();
  return new Date(subscription.current_period_end) > now;
}

export function isPremiumUser(subscription?: UserSubscription): boolean {
  if (!subscription) return false;

  return subscription.status !== 'legacy';
}

export function isStoreAccessible(subscription?: UserSubscription): boolean {
  return isPremiumUser(subscription) && hasValidSubscription(subscription);
}

export interface UserStats {
  gem_balance: number;
  google_email?: string | null;
  discord_email?: string | null;
  login_email?: string | null;
  email_verified: boolean | null;
  created_at: Date | null;
  is_internal: boolean;
  subscription: UserSubscription | null;
  marketing_opt_in: boolean;
}

export enum ProfileGender {
  MALE = 'male',
  FEMALE = 'female',
  NON_BINARY = 'non-binary',
}

export interface ChatProfile {
  id?: number;
  name: string;
  gender: string;
  bio: string;
}
