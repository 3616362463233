import React from 'react';
import Link from 'next/link';
import { cn } from '@/lib/utils';
import { useRouter } from 'next/router';
import { useCharactersStore } from '@/stores/characters';

export const MobileCategoryNav: React.FC = () => {
  const router = useRouter();
  const { setFilterTagsSelected } = useCharactersStore();

  // Determine active category based on current path
  const getActiveCategory = () => {
    const path = router.pathname;
    if (path === '/ai-hentai') return 'anime';
    if (path === '/ai-boyfriend') return 'male';
    if (path === '/ai-futa') return 'futa';
    return 'female'; // Default to female (index page)
  };

  // Check if we should show the category navigation
  const shouldShowCategoryNav = () => {
    const path = router.pathname;
    return path === '/' || path === '/ai-hentai' || path === '/ai-boyfriend' || path === '/ai-futa';
  };

  // Category navigation items with their corresponding filters
  const categoryNavItems = [
    { 
      name: 'Girls', 
      path: '/', 
      active: getActiveCategory() === 'female',
      filter: ['gender:female']
    },
    { 
      name: 'Anime', 
      path: '/ai-hentai', 
      active: getActiveCategory() === 'anime',
      filter: ['style:artistic']
    },
    { 
      name: 'Guys', 
      path: '/ai-boyfriend', 
      active: getActiveCategory() === 'male',
      filter: ['gender:male']
    },
    { 
      name: 'Futa', 
      path: '/ai-futa', 
      active: getActiveCategory() === 'futa',
      filter: ['gender:futa']
    },
  ];

  // Handle category click to set the appropriate filter
  const handleCategoryClick = (filter: string[]) => {
    // Force update the filter to the selected category
    setFilterTagsSelected([...filter]);
  };

  // Don't render anything if we shouldn't show the category nav
  if (!shouldShowCategoryNav()) {
    return null;
  }

  return (
    <div className="md:hidden flex items-center justify-center space-x-3 py-2 px-2 overflow-x-auto w-full bg-black-200 border-b border-gray fixed top-[56px] z-[21]">
      {categoryNavItems.map((item) => (
        <Link
          key={item.name}
          href={item.path}
          onClick={() => handleCategoryClick(item.filter)}
          className={cn(
            'py-1.5 px-4 rounded-xl transition-all duration-200 text-sm font-medium whitespace-nowrap',
            item.active
              ? 'bg-white/35 text-white shadow-lg'
              : 'text-white/80 hover:text-white hover:bg-white/10'
          )}
        >
          {item.name}
        </Link>
      ))}
    </div>
  );
}; 