import { useRouter } from 'next/router';
import { PropsWithChildren, useEffect } from 'react';
import { cn } from '@/lib/utils';
import { Sidebar } from '@/components/sidebar/Sidebar';
import { Header } from '@/components/Header';
import { MobileBottomMenu } from '@/components/MobileBottomMenu';
import { MobileCategoryNav } from '@/components/MobileCategoryNav';
import { useAppStore } from '@/stores/app';
import { useIsAuthenticated } from '@/hooks/useIsAuthenticated';
import { Main } from './Main';
import useIsMobile from '@/hooks/useIsMobile';

type Props = PropsWithChildren<{
  className?: string;
}>;

export const SidebarLayout = ({ children, className }: Props) => {
  const { isSidebarOpen, toggleSidebar } = useAppStore(s => s);
  const { pathname, query } = useRouter();
  const isChat = pathname.startsWith('/chat/') && query.activeTab !== 'all';

  const isLoggedIn = useIsAuthenticated();
  const isMobile = useIsMobile();
  
  useEffect(() => {
    // Only auto-open the sidebar if not on a route that starts with /character
    if (isLoggedIn && !isSidebarOpen && !isMobile && !pathname.startsWith('/character')) {
      toggleSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, pathname]);

  return (
    <div className={cn('flex min-h-dvh bg-black-200 md:h-dvh flex-1 md:overflow-hidden', className)}>
      <div className="flex flex-col flex-1 max-w-full md:overflow-hidden">
        <Header />
        <MobileCategoryNav />
        <div className="flex flex-row max-w-full md:overflow-hidden">
            <Sidebar toggleSidebar={toggleSidebar} isOpen={isSidebarOpen} />
            <Main>{children}</Main>
        </div>
        {!isChat && <MobileBottomMenu />}
      </div>
    </div>
  );
};
