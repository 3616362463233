import { cn } from '@/lib/utils';
import { useRouter } from 'next/router';
import { FC, PropsWithChildren } from 'react';

interface MainProps {
  className?: string;
}

export const Main: FC<PropsWithChildren<MainProps>> = ({ children, className }) => {
  const { pathname } = useRouter();
  const isChat = pathname.startsWith('/chat/');
  
  // Check if we should show the category navigation
  const shouldShowCategoryNav = () => {
    return pathname === '/' || pathname === '/ai-hentai' || pathname === '/ai-boyfriend' || pathname === '/ai-futa';
  };

  return (
    <main
      className={cn(
        'flex-1 md:z-[16] md:h-[calc(100dvh-60px)] md:custom-scrollbar md:pt-0 pb-0 border-l border-gray',
        isChat ? 'overflow-y-hidden h-[calc(100dvh)] overscroll-none pt-[56px]' : 
               shouldShowCategoryNav() ? 'pt-[96px] md:pt-0 px-2 md:px-8 md:pb-8 pb-[14px] overflow-x-hidden overscroll-auto' : 
               'pt-[56px] md:pt-0 px-2 md:px-8 md:pb-8 pb-[14px] overflow-x-hidden overscroll-auto',
        className
      )}
    >
      {children}
    </main>
  );
};
