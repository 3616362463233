import { useState } from 'react';
import Image, { ImageProps } from 'next/image';
import { cn } from '@/lib/utils';

interface Props extends ImageProps {
  skeletonClassName?: string;
  onLoaded?: () => void;
  onClick?: (e: React.MouseEvent | React.TouchEvent) => void;
}

export function SkeletonImage({ onLoaded, onClick, ...props }: Props) {
  const [loaded, setLoaded] = useState(false);

  function onLoad() {
    setLoaded(true);

    if (onLoaded) {
      onLoaded();
    }
  }

  // Handle both click and touch events
  const handleInteraction = (e: React.MouseEvent | React.TouchEvent) => {
    if (onClick) {
      // Prevent default behavior to avoid any browser-specific issues
      e.preventDefault();
      onClick(e);
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <Image
      {...props}
      onLoad={onLoad}
      onClick={handleInteraction}
      onTouchEnd={handleInteraction}
      className={cn('w-[${props.width}px] h-[${props.height}px]', props.className, !loaded && `skeleton bg-black-100`)}
    />
  );
}
