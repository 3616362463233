import React from 'react';
import { ICharacter } from '@/models/characters';
import { Text } from '@/components/Text';
import { default_avatar } from '@/assets/remote-assets';
import { cn } from '@/lib/utils';
import { useCharactersStore } from '@/stores/characters';
import { SkeletonImage } from '@/components/SkeletonImage';
import { getPersonalityAndRelationship } from '@/components/character-creation/util';
import { useRouter } from 'next/router';

interface Props {
  character: ICharacter;
  loading?: boolean;
  className?: string;
  centered?: boolean;
  nameOnly?: boolean;
}

export const ChatWelcomeMessage: React.FC<Props> = ({ character, loading, className, centered, nameOnly }) => {
  const { push } = useRouter();
  const { getTotalPendingImages } = useCharactersStore(s => s);
  
  const renderDescription = () => {
    if (character.created_by_user && character.description) {
      const { personality: personalityObj, relationship: relationshipObj } = getPersonalityAndRelationship(character.creation_choices?.look_choices?.gender, character.creation_choices?.look_choices?.style, character.description);
      return (
        <div className="flex mt-1 items-left">
          {personalityObj && (
            <div className="bg-base-100 rounded-sm px-0 py-1 mr-2 flex items-center z-10 w-max">
              <span className="mr-2 flex-shrink-0">{personalityObj.asset}</span>
              <Text variant="body-2" className="text-white whitespace-nowrap">{personalityObj.name}</Text>
            </div>
          )}
          {relationshipObj && (
            <div className="bg-base-100 rounded-sm px-2 py-1 flex items-center z-10 w-max">
              <span className="mr-2 flex-shrink-0">{relationshipObj.asset}</span>
              <Text variant="body-2" className="text-white whitespace-nowrap">{relationshipObj.name}</Text>
            </div>
          )}
        </div>  
      );
    }
    return character.description ? character.description.replace(/\{.*?\}/g, '').trim() : character.description;
  }

  return (
    <div className={cn('flex items-center mb-8', centered && 'flex-col text-center gap-1', className)}>
      {!nameOnly && (
        <button
          onClick={(event) => {
            event.stopPropagation();
            push(`/character/${character.id}`);
          }}
          className="focus:outline-none hover:brightness-110 transition duration-300 brightness-75 cursor-pointer"
        >
          <SkeletonImage
            className={cn(
              'rounded-full object-cover object-top border-white/100 hover:border-2',
              centered ? 'h-16 w-16' : 'h-12 w-12 mr-2'
            )}
            height={centered ? 64 : 48}
            width={centered ? 64 : 48}
            src={character.avatar_url || default_avatar}
            alt="avatar"
          />

        </button>
      )}
      <div>
        <Text className={cn('text-white -mb-0.5')} variant={centered ? 'h3' : 'h5'}>
          {character.name}
        </Text>

        {!nameOnly && (
          <Text
            className={cn('text-white max-w-[500px]', !centered && 'line-clamp-1 max-w-[300px]')}
            variant={centered ? 'body-1' : 'body-2'}
          >
            {/* Remove all markdown formatting, when the character is created by the user, the description will contain a json to string to record personality and relationship */}
            {loading ? 'Updating...' 
                  : renderDescription()}
          </Text>
        )}
      </div>
    </div>
  );
};
