import { personalities } from "@/components/character-creation/v2/creationData";
import { relationships } from "@/components/character-creation/v2/creationData";

export const getPersonalityAndRelationship = (gender?: string, style?: string, json_str?: string) => {
    if (json_str) {
        try {
            const lookupKey = `${style || 'realistic'}_${gender || 'female'}`;
            const match = json_str.match(/\{.*?\}/);
            const { personality, relationship } = JSON.parse(match ? match[0] : '{}');
            const personalityObj = personalities[lookupKey].find((p: any) => p.id === personality) || null;
            const relationshipObj = relationships[lookupKey].find((r: any) => r.id === relationship) || null;
            return { personality: personalityObj, relationship: relationshipObj };
        } catch (error) {
            console.error('Error parsing character description:', error);
            return { personality: null, relationship: null };
        }
    } else {
        return { personality: null, relationship: null };
    }
};
