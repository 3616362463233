import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import { Modals } from '@/types/modals';
import { useIsAuthenticated } from '@/hooks/useIsAuthenticated';
import { GemButton } from '@/components/buttons/GemButton';
import { useState, useEffect, useRef } from 'react';
import useIsMobile from '@/hooks/useIsMobile';
import { useUserStore } from '@/stores/user';
import { useAppStore } from '@/stores/app';
import { MenuButton } from '@/components/buttons/MenuButton';
import PricingItem from '@/components/PricingItem';
import { ChatWelcomeMessage } from '@/components/page-components/chat/ChatWelcomeMessage';
import { Button } from './buttons/Button';
import { Text } from './Text';
import { Promotion } from '@/types/products';
import { openModal } from './BaseModal';
import { useRouter } from 'next/router';
import { useCharactersStore } from '@/stores/characters';
import { useProductsStore } from '@/stores/products';
import { useCountdownTimer } from '@/hooks/useCountdownTimer';
import { cn } from '@/lib/utils';
import Link from 'next/link';
import ThotBotChatImage from '@/assets/icons/thot-bot-chat.svg';
import { MenuArrowsIcon } from '@/assets/icons/MenuArrows';
import { GemIcon, Settings, LogOut, Plus, PlusIcon, User, ChevronLeft } from 'lucide-react';
import posthog from 'posthog-js';
import { hasValidSubscription, isStoreAccessible } from '@/models/user';
import { CustomLink } from './CustomLink';
import { BeamerButton } from '@/components/buttons/BeamerButton';

export const Header: FC = () => {
  const isLoggedIn = useIsAuthenticated();
  const { characters, loading, filterTagsSelected, setFilterTagsSelected } = useCharactersStore(s => ({ characters: s.characters, loading: s.loading, filterTagsSelected: s.filterTagsSelected, setFilterTagsSelected: s.setFilterTagsSelected   }));
  const { userStats } = useUserStore();
  const { isSidebarOpen, toggleSidebar, setSignInUiType, setIsSignInModalVisible, logOut } = useAppStore(s => s);
  const { getNextPromotion, promotions, hasFreeGems, products } = useProductsStore();
  const [ nextPromotion, setNextPromotion] = useState<Promotion | null>(getNextPromotion());
  const [ freeGems, setFreeGems] = useState<boolean>(hasFreeGems());
  
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  const dropdownAccountMenuRef = useRef<HTMLDivElement>(null);

  const [isGemsMenuOpen, setIsGemsMenuOpen] = useState(false);
  const dropdownGemsMenuRef = useRef<HTMLDivElement>(null);
  
  const [mounted, setMounted] = useState(false);
  const isMobile = useIsMobile();

  const { timeLeft, timeStr, resetTimer } = useCountdownTimer(nextPromotion?.remaining_time ?? 0, () => {
    setNextPromotion(getNextPromotion);
  });


  const { push } = useRouter();
  const router = useRouter();
  const activeChatId = router.asPath.startsWith('/chat/') ? Number(router.query.id) : null;
  const activeChatUniqueCode = router.asPath.startsWith('/chat/') ? router.query.unique_code : null;
  const character = (activeChatId && characters.find(ch => ch.id === activeChatId)) || (activeChatUniqueCode && characters.find(ch => ch.unique_code === activeChatUniqueCode));

  // Determine active category based on current path
  const getActiveCategory = () => {
    const path = router.pathname;
    if (path === '/ai-hentai') return 'anime';
    if (path === '/ai-boyfriend') return 'male';
    if (path === '/ai-futa') return 'futa';
    return 'female'; // Default to female (index page)
  };

  // Check if we should show the category navigation
  const shouldShowCategoryNav = () => {
    const path = router.pathname;
    return path === '/' || path === '/ai-hentai' || path === '/ai-boyfriend' || path === '/ai-futa';
  };

  // Category navigation items with their corresponding filters
  const categoryNavItems = [
    { 
      name: 'Girls', 
      path: '/', 
      active: getActiveCategory() === 'female',
      filter: ['gender:female']
    },
    { 
      name: 'Anime', 
      path: '/ai-hentai', 
      active: getActiveCategory() === 'anime',
      filter: ['style:artistic']
    },
    { 
      name: 'Guys', 
      path: '/ai-boyfriend', 
      active: getActiveCategory() === 'male',
      filter: ['gender:male']
    },
    { 
      name: 'Futa', 
      path: '/ai-futa', 
      active: getActiveCategory() === 'futa',
      filter: ['gender:futa']
    },
  ];

  // Handle category click to set the appropriate filter
  const handleCategoryClick = (filter: string[]) => {
    // Force update the filter to the selected category
    setFilterTagsSelected([...filter]);
  };

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownAccountMenuRef.current && !dropdownAccountMenuRef.current.contains(event.target as Node)) {
        setIsAccountMenuOpen(false);
      }

      if (dropdownGemsMenuRef.current && !dropdownGemsMenuRef.current.contains(event.target as Node)) {
        setIsGemsMenuOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  useEffect(() => {
    setNextPromotion(getNextPromotion);
  }, [promotions]);

  useEffect(() => {
    setFreeGems(hasFreeGems());
  }, [products]);

  useEffect(() => {
    resetTimer(nextPromotion?.remaining_time ?? 0);
  }, [nextPromotion]);

  const onClickSignIn = () => {
    setSignInUiType('signinUI');
    setIsSignInModalVisible(true);
    openModal(Modals.SignIn);
    posthog?.capture('signin_click_header');
  }

  const handleViewProfile = () => {
    push('/profile');
  };

  const handleViewSubscription = () => {
    push('/premium');
  };

  const handleLogout = () => {
    const options = window ? { callbackUrl: window.location.origin + '/' } : undefined;
    logOut(options);

    posthog?.capture('logged_out');
  };

  const scrollToTop = () => {
    const chatElement = document.getElementById('chat-history-container'); // Ensure you have an element with this ID in your chat component
    if (chatElement) {
      chatElement.scrollTo({
        top: -chatElement.scrollHeight + chatElement.clientHeight,
        behavior: 'smooth',
      });
    }
  };

  const handleViewStore = () => {
    openModal(Modals.Storage);
    posthog?.capture('view_store', {'value': userStats.gem_balance});
  }

  const handleMenuButtonClick = () => {
    setIsAccountMenuOpen(false);
    setIsGemsMenuOpen(false);
    toggleSidebar();
  }; 

  const renderAccountDropdown = () => (
    <div 
      className="fixed right-0 w-auto mt-2 origin-top-right bg-black-100 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[25]"
      style={{
        top: dropdownAccountMenuRef.current ? dropdownAccountMenuRef.current.getBoundingClientRect().bottom : 0,
        right: dropdownAccountMenuRef.current ? window.innerWidth - dropdownAccountMenuRef.current.getBoundingClientRect().right : 0,
      }}
      onClick={(e) => {
        console.log('dropdown clicked');
      }}
    >
      <div className="px-1 py-1">
        <button className="group flex rounded-md items-center w-full px-2 py-2 text-sm text-gray-900 hover:bg-black-200 hover:text-white"
          onClick={(e) => {
            e.stopPropagation();
            setIsAccountMenuOpen(false);
            handleViewProfile();
          }}
        >
          <Settings className="w-5 h-5 mr-2" /> Settings
        </button>

        <button className="group flex flex-grow rounded-md items-center w-full px-2 py-2 text-sm text-gray-900 hover:bg-black-200 hover:text-white mr-10"
          onClick={(e) => {
            e.stopPropagation();
            setIsAccountMenuOpen(false);
            handleViewSubscription();
          }}
        >
          <GemIcon className="w-5 h-5 mr-2" /> Subscription
        </button>

        <button className="group flex rounded-md items-center w-full px-2 py-2 text-sm text-gray-900 hover:bg-black-200 hover:text-white"
          onClick={(e) => {
            e.stopPropagation();
            setIsAccountMenuOpen(false);
            handleLogout();
          }}
        >
          <LogOut className="w-5 h-5 mr-2" /> Logout
        </button>
      </div>
    </div>
  );

  const renderGemsDropdown = () => (
    <div 
      className="fixed right-0 w-auto mt-2 origin-top-right bg-black-100 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[25]"
      style={{
        top: dropdownGemsMenuRef.current ? dropdownGemsMenuRef.current.getBoundingClientRect().bottom : 0,
        right: dropdownGemsMenuRef.current ? window.innerWidth - dropdownGemsMenuRef.current.getBoundingClientRect().right : 0,
      }}
      onClick={(e) => {
        console.log('dropdown clicked');
      }}
    >
      <div className="px-2 py-1">
        
        <PricingItem title="Text Message" price={0} />
        <div className="w-full h-[1px] bg-white opacity-10 "/>
        <PricingItem title="Receive SFW Image" price={0} />
        <div className="w-full h-[1px] bg-white opacity-10 "/>
        <PricingItem title="Voice Message" price={25} />
        <div className="w-full h-[1px] bg-white opacity-10 "/>
        <PricingItem title="Unlock NSFW Image" price={100} />
        <div className="w-full h-[1px] bg-white opacity-10 "/>
        <PricingItem title="Generate Image" price={100} />
        <div className="w-full h-[1px] bg-white opacity-10 "/>
        <PricingItem title="Create Character" price={200} />


        <div className="flex justify-center">
          {userStats.subscription && isStoreAccessible(userStats.subscription) ? (
            <Button
              className={cn('w-auto border-black mt-4 ml-2 mr-2')}
              wrapperClass={'bg-pink-gr group-active/button:bg-white-gr justify-start px-[32px] py-3'}
              onClick={() => {
                setIsGemsMenuOpen(false);
                handleViewStore();
              }}
            >
              <Text className="transition-all duration-150 text-white group-active/button:text-black" variant="btn-1">
                Buy More
              </Text>
              <PlusIcon className="w-5 h-5 ml-4 text-white"/>
            </Button>
          ) : (
            <Button
              className={cn('w-full border-black mt-2 mb-2 ml-4 mr-4')}
              wrapperClass={'bg-pink-gr group-active/button:bg-white-gr justify-start px-[32px] py-2'}
              onClick={() => {
                setIsGemsMenuOpen(false);
                handleViewSubscription();
              }}
            >
              <GemIcon className="w-5 h-5 text-white" />
              <Text className="transition-all duration-150 text-white group-active/button:text-black text-center w-full" variant="btn-1">
                Premium
              </Text>
            </Button>
          )}
        </div>
      </div>
    </div>
  );

  const renderPromotion = () => {
    if (!nextPromotion && !freeGems) return null;
    return (
      <div className="h-11 px-3 py-2 bg-black-100 rounded-3xl justify-center items-center gap-1.5 inline-flex mr-2 border-2 border-pink hover:cursor-pointer relative overflow-hidden group"
        onClick={handleViewStore}
        title={nextPromotion?.name}
    >
      <div className="absolute inset-0 w-full h-full bg-gradient-to-r from-transparent via-white/20 to-transparent -translate-x-full group-hover:translate-x-full transition-transform duration-1000 ease-in-out" />
      <Text className="text-btn-2 md:text-btn-1 relative z-10" textColor="white" variant="btn-1">
        {
          freeGems ? 'Free Gems 👉' : timeStr
        }
      </Text>
    </div>
    );
    
  }
  
  return (
    <header
      onClick={scrollToTop}
      className={cn(
        'bg-black-100 text-white flex justify-end md:justify-between items-center pr-5 md:pr-8 pt-2.5 pb-2 md:pt-3 md:pb-3 transition-all z-[25] fixed left-0 top-0 md:relative w-full border-b border-gray max-h-[56px]',
        // isSidebarOpen ? 'pl-3 md:pl-8' : 'pl-[70px] md:pl-[100px]',
      )}
    >
      <div className="flex items-center w-full justify-between" onClick={(event) => event.stopPropagation()}>

      <div className="flex mt-1 items-center justify-center ml-5" onClick={(event) => event.stopPropagation()}>
        {(!isMobile || !character || router.query.activeTab === 'all') && (
          <div>
            <MenuButton className={isSidebarOpen ? 'hidden' : 'w-[40px] h-[40px]'} onClick={handleMenuButtonClick} />
            <Button
                onClick={handleMenuButtonClick}
                className={cn("w-auto z-0", !isSidebarOpen ? "hidden" : "w-[40px] h-[40px]")}
                wrapperClass="border border-white bg-black-200 group-active/button:bg-white"
              >
                <MenuArrowsIcon className="-ml-[3px]" pathClassName="stroke-white group-active/button:stroke-black" />
            </Button>
          </div>
        )}

        {(character && isMobile && router.query.activeTab !== 'all') ? (
          <button
            onClick={() => {
              if (isLoggedIn) {
                router.push({ pathname: router.pathname, query: { ...router.query, activeTab: 'all' } });
              } else {
                router.push("/");
              }
            }}
          >
              <div className="flex flex-row items-center justify-center">
                <ChevronLeft className="w-6 h-6 mr-1" />
                <ChatWelcomeMessage character={character} loading={loading} className="flex mb-0" nameOnly={true}/>
              </div>
          </button>
        ) : (
          <div className={"flex items-center py-2.5 px-2 md:px-5"}>
            <CustomLink href="/" aria-label="go to home page">
              <ThotBotChatImage />
            </CustomLink>
          </div>
        )}
      </div>

      {/* Desktop Category Navigation */}
      {shouldShowCategoryNav() && (
        <div className="hidden md:flex items-center space-x-2 ml-4">
          {categoryNavItems.map((item) => (
            <Link
              key={item.name}
              href={item.path}
              onClick={() => handleCategoryClick(item.filter)}
              className={cn(
                'py-1.5 px-4 rounded-xl transition-all duration-200 text-sm font-medium',
                item.active
                  ? 'bg-white/35 text-white shadow-lg'
                  : 'text-white/80 hover:text-white hover:bg-white/10'
              )}
            >
              {item.name}
            </Link>
          ))}
        </div>
      )}

      <div className="flex items-center justify-center gap-2 h-[50px] overflow-x-scroll" onClick={(event) => event.stopPropagation()}>
        {/* {renderFilterTags()} */}
      </div>
      <div className="flex-grow flex items-center overflow-hidden pl-1 md:pl-1"/> 
        
        {!isLoggedIn ? (
          <Button
            onClick={onClickSignIn}
            className="w-auto"
            wrapperClass="h-[36px] py-2 bg-blue-gr group-active/button:bg-white-gr"
          >
            <Text
              className={`px-[27px] transition-all duration-150 text-white group-active/button:text-black ${isMobile ? 'text-sm' : ''}`}
              textColor={'white'}
              variant={isMobile ? 'btn-2' : 'btn-1'}
            >
              Sign in
            </Text>
          </Button>
        ) : (
          <>
            {/* {renderPromotion()} */}

            {/* {Go Premium Button} */}
            {userStats && (userStats.login_email || userStats.google_email || userStats.discord_email) && (userStats.subscription === null || !hasValidSubscription(userStats.subscription)) && userStats.gem_balance === 0 && (
              <div className="sm:flex relative inline-block text-left" 
                ref={dropdownGemsMenuRef}
                onClick={(e) => {
                  setIsAccountMenuOpen(false);
                  setIsGemsMenuOpen(!isGemsMenuOpen);
                }}
              >
                <Button
                  className={cn('w-auto border-black mt-1 ml-2')}
                  wrapperClass={'h-[36px] bg-pink-gr group-active/button:bg-white-gr justify-start px-3 py-2'}
                  onClick={() => {
                    setIsGemsMenuOpen(false);
                    handleViewSubscription();
                  }}
                >
                  <GemIcon className="w-4 h-4 mr-1" />
                  <Text className="mt-1 transition-all duration-150 text-white text-nowrap group-active/button:text-black" variant="h6">
                    Premium 70% OFF
                  </Text>
                </Button>
              </div>
            )}

            {/* {Gems Button} */}
            {userStats.subscription && (isStoreAccessible(userStats.subscription) || userStats.gem_balance > 0) && (
              <>
                <BeamerButton className="ml-2" />
                <div className="ml-2 relative inline-block text-left" 
                  ref={dropdownGemsMenuRef}
                  onClick={(e) => {
                    setIsAccountMenuOpen(false);
                    setIsGemsMenuOpen(!isGemsMenuOpen);
                  }}
                >
                  <GemButton 
                    onClick={()=>{}}
                    amount={userStats.gem_balance} 
                    className="h-[36px]"
                  />
                  {mounted && isGemsMenuOpen && ReactDOM.createPortal(
                    renderGemsDropdown(),
                    document.body
                  )}
                </div>
              </>
            )}

            {/* {Account Button} */}
            <div className="relative ml-2 hidden sm:flex text-left" ref={dropdownAccountMenuRef}>
              <button
                onClick={(e) => {
                  setIsGemsMenuOpen(false);
                  setIsAccountMenuOpen(!isAccountMenuOpen);
                }}
                className="h-[36px] inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-black-200 rounded-md hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
              >
                {userStats.subscription?.status === 'active' ? <User className="w-6 h-6 text-pink" /> : <User className="w-6 h-6" />}
                <span className="hidden md:inline ml-2 mr-1">Account</span>

                <svg className="w-5 h-5 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>

              {mounted && isAccountMenuOpen && ReactDOM.createPortal(
                renderAccountDropdown(),
                document.body
              )}
            </div>
          </>
        )}
      </div>
    </header>
  );
};
